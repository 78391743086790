<ng-container *ngIf="data">
    <div class="mb-3 px-5 py-3 bg-white rounded-1 position-absolute w-100 zIndex shadow">
        <div class="d-flex justify-content-between align-items-center">
            <div class="logo">
                <ng-container *ngIf="data?.logo?.includes('public'); else privateLogo">
                    <img class="img img-fluid" width="176" height="34" [src]="data?.logo" alt="Education Loan" [ngClass]="{'custom-logo':data.isPartnerLogo}" *ngIf="data?.logo!==undefined">
                </ng-container>
                <ng-template #privateLogo>
                    <fa-async-image class="img img-fluid" width="'176'" height="'34'" [src]="data?.logo" [ngClass]="{'custom-logo':data.isPartnerLogo}" *ngIf="data?.logo!==undefined"></fa-async-image>
                </ng-template>
            </div>
            <div class="d-flex justify-content-end gap-5">
                <div class=" banner " *ngIf="data?.loan ">
                    <div class="bg-primary-light-gradient p-2 ">
                        <div class="d-flex justify-content-between align-items-center ">
                            <div class=" ">
                                <div *ngIf="data?.loan?.loanType==='Unsecured Loan(without Property)' "> <img src="../../../../assets/logo/collateral.svg " alt="icon " class="img img-fluid me-2 "> Loan with Co-applicant</div>
                                <div *ngIf="data?.loan?.loanType==='Secured Loan(with Property)' "><img src="../../../../assets/logo/housing.svg " alt="icon " class="img img-fluid me-2 mt-n1 ">Loan with property</div>
                                <div *ngIf="data?.loan?.loanType==='International Loan(Non Collateral-Cosigner)' ">
                                    <img src="../../../../assets/logo/dollar.svg " alt="icon " class="img img-fluid me-2 ">No-cosigner Loan
                                </div>
                            </div>
                            <p class="text-dark fw-bold mb-0 ms-3 fs-5 " *ngIf="data?.loan?.loanAmount ">
                                {{data?.loan?.loanAmountCurrency}} {{prepareViewLoanAmount(data?.loan?.loanAmount)}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="needHelp">
                    <img src="../../../../assets/logo/message-question.svg " alt="icon " class="img img-fluid mx-4 ">
                    <div class="text-decoration-underline text-secondary cursor-pointer" (click)="raiseARequest()">Need Help?
                    </div>

                </div>
                <div class="logout px-1 py-2" *ngIf="data?.logout " (click)="logout() ">
                    <svg width="24 " height="24 " viewBox="0 0 24 24 " fill="none " xmlns="http://www.w3.org/2000/svg ">
                        <path
                            d="M15.2405 22.27H15.1105C10.6705 22.27 8.5305 20.52 8.1605 16.6C8.1205 16.19 8.4205 15.82 8.8405 15.78C9.2405 15.74 9.6205 16.05 9.6605 16.46C9.9505 19.6 11.4305 20.77 15.1205 20.77H15.2505C19.3205 20.77 20.7605 19.33 20.7605
                15.26V8.73998C20.7605 4.66998 19.3205 3.22998 15.2505 3.22998H15.1205C11.4105 3.22998 9.9305 4.41998 9.6605 7.61998C9.6105 8.02998 9.2605 8.33998 8.8405 8.29998C8.4205 8.26998 8.1205 7.89998 8.1505 7.48998C8.4905 3.50998 10.6405 1.72998 15.1105
                1.72998H15.2405C20.1505 1.72998 22.2505 3.82998 22.2505 8.73998V15.26C22.2505 20.17 20.1505 22.27 15.2405 22.27Z "
                            fill="#292D32 " />
                        <path
                            d="M14.9991 12.75H3.61914C3.20914 12.75 2.86914 12.41 2.86914 12C2.86914 11.59 3.20914 11.25 3.61914 11.25H14.9991C15.4091 11.25 15.7491 11.59 15.7491 12C15.7491 12.41 15.4091 12.75 14.9991 12.75Z "
                            fill="#292D32 " />
                        <path
                            d="M5.84945 16.1001C5.65945 16.1001 5.46945 16.0301 5.31945 15.8801L1.96945 12.5301C1.67945 12.2401 1.67945 11.7601 1.96945 11.4701L5.31945 8.12009C5.60945 7.83009 6.08945 7.83009 6.37945 8.12009C6.66945 8.41009 6.66945 8.89009
                6.37945 9.18009L3.55945 12.0001L6.37945 14.8201C6.66945 15.1101 6.66945 15.5901 6.37945 15.8801C6.23945 16.0301 6.03945 16.1001 5.84945 16.1001Z "
                            fill="#292D32 " />
                    </svg> Logout
                </div>

            </div>

        </div>
        <!-- 
        {{data|json}} -->
    </div>
</ng-container>

<ng-template #content let-modal>
    <div class="modal-body">
        <div class="px-5">
            <div class="text-center text-primary-dark mb-6 py-4">
                <h4 class="mb-2">Your request has been send successfully. Our team will get back to you in 2 3 working days
                </h4>
                <p class="text-muted">Thanks for co-operating with us </p>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-n6">
                <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1677050781144woman%20in%20online%20meeting%20with%20graphic%20tablet.png" alt="Thank you" class="img img-fluid">
            </div>
        </div>
    </div>
    <div class="modal-footer px-4">
        <a class="btn btn-primary w-100 text-light" (click)="modal.close('Close click');">Thank
            you!</a>
    </div>
</ng-template>
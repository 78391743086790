import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

export interface DocumentTableData {
  sampleImageUrl?: string,
  columns?: string[],
  tableData?: any[],
  dataProcessor?: any,
  downloadAction?:any,
  deleteAction?:any,
  documentType?:string,
}

@Component({
  selector: 'apnst-formly-document-table',
  templateUrl: './formly-document-table.component.html',
  styleUrls: ['./formly-document-table.component.scss']
})
export class FormlyDocumentTableComponent extends FieldType implements OnInit {
  public data: DocumentTableData;

  ngOnInit(): void {
    this.setDocumentTableData();
  }

  setDocumentTableData(){
    this.data = {...this.to?.documentTableData};
    if(this.to?.documentTableData?.dataProcessor){
      this.data.tableData = this.data?.dataProcessor(this.data?.tableData);
    }
  }

  downloadAction(row:any, ev:any){
    if(this.to?.documentTableData && this.to?.documentTableData?.downloadAction){
      this.to?.documentTableData?.downloadAction(row);
    }
  }

  getDocumentName(url:string){
    return decodeURIComponent(new URL(url).pathname.split("/").pop().substring(13));
  }

  deleteAction(row:any, ev:any){
    ev.stopPropagation();
    if(this.to?.documentTableData && this.to?.documentTableData?.deleteAction){
      this.to?.documentTableData?.deleteAction(row);
    }
  }

}

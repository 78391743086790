import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-input-tooltip-text',
  templateUrl: './formly-input-tooltip-text.component.html',
  styleUrls: ['./formly-input-tooltip-text.component.scss'],

})
export class FormlyInputTooltipTextComponent extends FieldType  {


}

<div [class]="to.className || 'row'">
  <label
    *ngFor="let option of to.options; let i = index"
    class="form-check border rounded-5 p-2"
    [ngClass]="option.column"
    [class.form-check-inline]="to.formCheck === 'inline'">
    <div class="d-flex justify-content-between">
      <img class="img-fluid" [src]="option.iconURL" />
      <input
      type="radio"
      [id]="id + '_' + i"
      class="form-check-input"
      [name]="field.name || id"
      [class.is-invalid]="showError"
      [attr.value]="option.value"
      [value]="option.value"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [attr.disabled]="option.disabled || formControl.disabled ? true : null" />
    </div>
  <div>
    <p class="mt-3" [for]="id + '_' + i">{{ option.label }}</p>
  </div>
  </label>
</div>


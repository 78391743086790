import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ApiHelperService } from '../../services/api-helper.service';
import { MediaService } from '../../services/media.service';
import { PassDataToEditLoanAppService } from './pass-data-to-edit-loan-app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TwoWayBindService } from '../../services/two-way-bind.service';

@Component({
  selector: 'apnst-formly-multi-field-file',
  templateUrl: './formly-multi-field-file.component.html',
  styleUrls: ['./formly-multi-field-file.component.scss']
})
export class FormlyMultiFieldFileComponent extends FieldType {
  @ViewChild("openErrorPopup") openErrorPopup;
  mimeTypes = {
    "files": {
      type: [".jpg", ".jpeg", ".png", ".gif", ".pdf", ".mp4"],
      mime: ["image/jpg", "image/jpeg", "image/png", "image/gif", "application/pdf", "video/mp4"],
    },
    "document": {
      types: [".pdf"],
      mime: ["application/pdf"]
    },
    "image": {
      types: [".jpg", ".png", ".gif"],
      mime: ["image/jpg", "image/jpeg", "image/png", "image/gif"]
    },
    "video": {
      type: [".mp4"],
      mime: ["video/mp4"]
    }
  }
  progress: number = 0;
  selectedFiles: any = [];
  files: File;
  @ViewChild("fileInput") fileInputRef: ElementRef<HTMLInputElement>
  constructor(
    private mediaService: MediaService,
    private cd: ChangeDetectorRef,
    private apiHelperService: ApiHelperService,
    private modalService: NgbModal,
    private twoWay: TwoWayBindService,
    private passDataToEditLoanAppEvent: PassDataToEditLoanAppService) {
    super();
  }
  get type() {
    return this.field.type;
  }

  uploadMedia(fileList: FileList) {
    const file = fileList.item(0);
    if (fileList.length > 30) {
      return this.modalService.open(this.openErrorPopup, {
        centered: true,
        size: 'l'
      })
      //this.apiHelperService.openSnackBar("Please upload less than or 30 documents!");
      // return;
    }
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        this.files = file
        if (!this.mimeTypes["files"]['mime'].includes(file.type)) {
          this.fileInputRef.nativeElement.value = "";
          this.selectedFiles = [];
          return this.modalService.open(this.openErrorPopup, {
            centered: true,
            size: 'l'
          })
          //this.apiHelperService.openSnackBar("Please upload valid file");
          //return;
        }

        if (file.size > 5 * 1024 * 1024) {
          // this.apiHelperService.openSnackBar("Please upload file of size less than 5mb");
          this.fileInputRef.nativeElement.value = "";
          this.selectedFiles = [];
          return this.modalService.open(this.openErrorPopup, {
            centered: true,
            size: 'l'
          })
          //return;
        }


        this.mediaService.uploadMedia(file).subscribe((data: any) => {
          if (data && data.progress) {
            this.progress = data.progress;

          }
          if (data && data.body) {

            this.selectedFiles.push(data.body[0]["Location"]);
            this.formControl.setValue(this.selectedFiles);

            this.fileInputRef.nativeElement.value = "";
            this.progress = 0;
          }
          this.formControl.markAsTouched();
          this.cd.detectChanges();
        });

      }

    }

  }

  goBack() {
    this.modalService.dismissAll();
  }

  removeImage() {
    this.formControl.setValue(null);
    this.selectedFiles = [];
  }


  isImage(url: string) {
    var arr = ["jpeg", "jpg", "gif", "png"];
    var ext = url.substring(url.lastIndexOf(".") + 1);
    if (arr.includes(ext)) {
      return true;
    } else {
      return false;
    }
  }
  downloadLoanDocumentsByType(documentType: string) {

    this.passDataToEditLoanAppEvent.passDataToEditLoanAppEvent.emit(documentType);

    // this.spinner.show();
    // this.apiHelperService.downloadZip(`loan-applications/${this.loanId}`, "sixmonthsbankstatements").pipe(map((res: any) => {
    //   return b64ToBlob(res, "application/zip");
    // })).subscribe(data => {
    //   fileSaver.saveAs(data, `documents-${this.loanId}.zip`);
    //   this.spinner.hide();
    // }, error => {
    //   this.spinner.hide();
    // })
  }

  getFileNameFromS3Path(s3Path: any) {
    const filenameWithExtension = s3Path.split('/').pop();
    const filenameWithoutNumbers = filenameWithExtension.replace(/[0-9]/g, '');
    const fileNamewithoutSpecial = filenameWithoutNumbers.replace(/[^a-zA-Z. ]/g, "")
    if (fileNamewithoutSpecial === "") {
      return "default";
    }
    if (fileNamewithoutSpecial?.length > 10) {
      return `${fileNamewithoutSpecial.slice(0, 10) + "..."}`
    }
    else {
      return fileNamewithoutSpecial;
    }
  }
}

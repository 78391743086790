<p class="mb-1" *ngIf="to?.attributes?.filterTitle">{{to?.attributes?.filterTitle}}</p>
<mat-select [formControl]="formControl" multiple class="form-select" [formlyAttributes]="field"
    [placeholder]="to.placeholder" [required]="to.required">
    <mat-select-trigger>

        {{formControl.value?.[0] || ''}}

        <span *ngIf="(formControl.value?.length || 0) > 1" class="example-additional-selection">

          (+{{(formControl.value?.length || 0) - 1}} {{formControl.value?.length === 2 ? 'other' : 'others'}})

        </span>

      </mat-select-trigger>
    <mat-option *ngFor="let single of to.options" [value]="single.value">{{single.label}}</mat-option>

</mat-select>

import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentVars } from '../interfaces/environment-vars';
import { UserIdentityService } from './user-identity.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment: EnvironmentVars,
    private userIdentityService: UserIdentityService
    ) { }

    private serverPath: string = this.environment.pathapi;

  uploadMedia(file:File){
    const userId = this.userIdentityService.getUserDetails().id; 
    const formData: FormData = new FormData();
    formData.append('file', file);
    let progress:number;
    return this.http
      .post(this.serverPath + "/buckets/" + userId + "/upload" , formData, { reportProgress: true,
        observe: 'events'}).pipe(
          map(event => {
            switch (event.type) {  
              case HttpEventType.UploadProgress:  
                progress = Math.round(event.loaded * 100 / event.total);
                return {progress};  
               // break {progress};  
              case HttpEventType.Response:  
                return event;  
            }  
          }),  
          catchError((error: HttpErrorResponse) => {   
            return of(`${file.name} upload failed.`);  
          })); 
  }
}

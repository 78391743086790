import { Directive, Input, OnInit, ElementRef } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { UserIdentityService } from "../services/user-identity.service";

@Directive({
  selector: "[apnstAccessControl]",
})
export class AccessControlDirective implements OnInit {
  @Input("permission") permission: string;
  constructor(
    private elementRef: ElementRef, 
    private auth: AuthService
    ) {
    }

  ngOnInit() {
    this.elementRef.nativeElement.style.display = "none";
    this.checkAccess();
  }
   checkAccess() {
    const accessControls: any = this.auth.getPermissions();
    if(accessControls){
      this.elementRef.nativeElement.style.display = accessControls[this.permission] ? "block" : "none";
    }
  }
}

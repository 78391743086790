import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-wrapper-accordian',
  templateUrl: './formly-wrapper-accordian.component.html',
  styleUrls: ['./formly-wrapper-accordian.component.scss']
})
export class FormlyWrapperAccordianComponent extends FieldWrapper {

}

<div class="input-group">
    <select class="form-select form-select-sm" [formControl]="operator" aria-label="Select type">
        <option value="">Select</option>
        <option value="gt">Greater Than</option>
        <option value="lt">Less Than </option>
        <option value="between">Range</option>
    </select>
    <input
        type="number"
        [formControl]="fromNumber"
        class="form-control form-control-sm"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"/>
    <input
    type="number"
    [formControl]="toNumber"
    class="form-control form-control-sm"
    [formlyAttributes]="field" 
    [class.is-invalid]="showError"
    *ngIf="operator.value === 'between'"/>
</div>
import { Directive, Input, TemplateRef, HostListener, OnInit, ElementRef, ComponentRef } from '@angular/core';
import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Directive({
  selector: '[apnstTooltip]'
})
export class TooltipDirective implements OnInit {
  @Input('apnstTooltip') content: TemplateRef<any>;
  @Input('configtooltip') data:TemplateRef<any>;
  private overlayRef: OverlayRef;
  @Input() config: any = {
    position: {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top'
    },

  };



  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) { }

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        this.config.position
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
    this.overlayRef.backdropClick().subscribe((value) => { this.close() });
  }

  @HostListener('click')
  toggle() {
    if (this.overlayRef.hasAttached()) {
      this.close();
    } else {
      this.open();
    }
  }
  close() {
    this.overlayRef.detach();
  }

  open() {
    const tooltipPortal = new ComponentPortal(TooltipComponent);

    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
      tooltipPortal
    );

    if (typeof (this.content) === 'string') {
      tooltipRef.instance.text = this.content;
    } else {
      tooltipRef.instance.content = this.content;
    }
    tooltipRef.instance.contents = this.data;
  }

}
import { Injectable, OnDestroy } from '@angular/core';
import { LoanDetails, StudentDetails } from 'projects/foreign-admits/src/app/loan-gtm/loan-gtm-interface/loan-gtm.interface';
import { ApiHelperService } from './api-helper.service';
import { UserIdentityService } from './user-identity.service';

@Injectable({
  providedIn: 'root'
})
export class LoanGtmService implements OnDestroy {
  studentDetails: StudentDetails = {
    module: "Ancillary",
    source: "LandingPage",
    service: "Loan",
    type: "student",
    roleId: "student",
    roleName: "student",
    services: ["Loan"],
    servicesTaken: 1,
    usersAvailOtp: true,
  };
  loanDetails: LoanDetails = {
    callStatus: "NOT_CALLED",
    status: "applied",
    appliedVia: "widget"
  };
  partnerDetails: any;
  intervalId: any;
  isPrefilledDataOnBack: boolean = false;
  universityBankMessage: string;
  constructor(
    private apiHelper: ApiHelperService,
    private userIdentity: UserIdentityService
  ) {
    this.getSavedDetailsFromLocalStorage();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  setDefaultDetails() {
    this.userIdentity.logout();
    if (this.studentDetails?.universityVendors) {
      delete this.studentDetails?.universityVendors;
    }
    this.isPrefilledDataOnBack = false;
    localStorage.removeItem("loanDetails");
    localStorage.removeItem("token");
    localStorage.removeItem('ttl');
    localStorage.removeItem("userId");
    sessionStorage.removeItem("stepHeading");
    localStorage.removeItem("studentDetails");
    localStorage.removeItem("puid");
    localStorage.removeItem("userDetails");
    this.studentDetails = {
      module: "Ancillary",
      source: "LandingPage",
      service: "Loan",
      type: "student",
      roleId: "student",
      roleName: "student",
      services: ["Loan"],
      servicesTaken: 1,
      usersAvailOtp: true
    };


    this.loanDetails = {
      callStatus: "NOT_CALLED",
      appliedVia: "widget"
    };
  }

  getSavedDetailsFromLocalStorage() {
    const stringifiedData: string = localStorage.getItem("studentDetails");
    const stringifiedDataLoan: string = localStorage.getItem("loanDetails");
    if (stringifiedData) {
      let studentDetails = JSON?.parse(stringifiedData);
      if (studentDetails) {
        this.studentDetails = { ...studentDetails };
        // if(this.studentDetails?.universityVendors){
        //   delete this.studentDetails?.universityVendors;
        // }
      }
    }
    if (stringifiedDataLoan) {
      let loanDetails = JSON?.parse(stringifiedDataLoan);

      if (loanDetails) {
        this.loanDetails = { ...loanDetails };
      }
    }

  }

  saveStudentDetails(studentDetails: any, updateRequest?: boolean): Promise<any> {
    this.studentDetails = Object.assign(this.studentDetails, studentDetails);
    this.studentDetails = this.removeEmpty(this.studentDetails);
    localStorage.setItem("studentDetails", JSON.stringify(this.studentDetails));
    delete this.studentDetails?.kycStatus;
    if (updateRequest) {
      this.apiHelper.updateData("users", this.studentDetails?.id, this.studentDetails).subscribe(() => {
      })
    }
    return new Promise((res, rej) => res(this.studentDetails));
  }

  saveLoanApplication(loanApplication: any, updateRequest?: boolean) {

    this.loanDetails = Object.assign(this.loanDetails, loanApplication);
    this.loanDetails = this.removeEmpty(this.loanDetails);
    localStorage.setItem("loanDetails", JSON.stringify(this.loanDetails));
    if (updateRequest) {
      this.apiHelper.updateData("loan-applications", this.loanDetails?.id, this.loanDetails);
    }
    return new Promise((res, rej) => res(this.loanDetails));
  }

  createStudent(): Promise<any> {
    return this.apiHelper.postData("users", this.studentDetails).toPromise();
  }

  createLoanApplication(loanDetails?: LoanDetails): Promise<any> {
    this.loanDetails = Object.assign(this.loanDetails, loanDetails);

    this.loanDetails = this.removeEmpty(this.loanDetails);
    if (this.partnerDetails) {
      this.loanDetails["partnerId"] = this.partnerDetails.id;
    }
    if (this.loanDetails?.id) {

      if (this.loanDetails?.loanType === "International Loan(Non Collateral-Cosigner)") {
        delete this.loanDetails?.coApplicantIncome;
        delete this.loanDetails?.coApplicantIncomeRange;
        delete this.loanDetails?.coApplicantPhoneNumber;
        delete this.loanDetails?.coApplicantProfession;
        delete this.loanDetails?.coApplicantRelation;
        delete this.loanDetails?.coApplicantName;
        delete this.loanDetails?.valueOfCollateral;
        delete this.loanDetails?.typeOfCollateral;
        delete this.loanDetails?.coApplicantPan;
        delete this.loanDetails?.coApplicantPincode;
        delete this.loanDetails?.coApplicantOccupation;
        delete this.loanDetails?.collateralPropertyLoanExist;
      }
      if (this.loanDetails?.loanType === "Unsecured Loan(without Property)") {
        delete this.loanDetails?.valueOfCollateral;
        delete this.loanDetails?.typeOfCollateral;
      }
      return this.apiHelper.updateData("loan-applications", this.loanDetails?.id, this.loanDetails).toPromise();
    }
    // else{

    // }
    return this.apiHelper.postData("loan-applications", this.loanDetails).toPromise();
  }

  getLoanApplication(studentId: string): Promise<any> {
    return this.apiHelper.getData("loan-applications", { where: { studentId: studentId }, limit: 1 })
  }
  getStudentDetailsById(id: string): Promise<any> {
    return this.apiHelper.getData("users/widget", { where: { id: id }, limit: 1 });
  }
  updateLoanApplication(): Promise<any> {
    return this.apiHelper.updateData("loan-applications", this.loanDetails?.id, this.loanDetails).toPromise();
  }

  updateLoanLeadToApplied() {
    return this.apiHelper.updateMultipleRecord("leads", { studentId: this.studentDetails?.id, service: "Loan" }, { isLoanApplied: true }).toPromise();
  }

  removeEmpty(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
      else if (typeof obj[propName] == 'object') {
        this.removeEmpty(obj[propName]);
      }
    }
    return obj
  }

  isMobileIndian(mobile: string): boolean {
    if (mobile) {
      return (mobile.startsWith("+91") && mobile.length === 13);
    }
    return true;
  }

  getLocation(pincode: string): Promise<any> {
    return this.apiHelper.getData("locations", { where: { pincode: pincode }, limit: 1 });
  }

  removeUndefined(obj) {
    let result = [];
    for (const single of obj) {
      if (single !== undefined && single !== null && single !== 'undefined' && single !== 'null') {
        result.push(single)
      }
    }
    return result;
  }

  getStudentDetails(mobile: string): Promise<any> {
    return this.apiHelper.getData("users/widget", { where: { mobile: mobile }, limit: 1 });
  }
}

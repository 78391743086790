<div [ngClass]="{'d-flex': to.orientation ==='vertical' }">
    <ul ngbNav #nav="ngbNav"  [orientation]="to.orientation" [ngClass]="to.navType">
    <li *ngFor="let tab of field.fieldGroup; let i = index; let last = last;" [ngbNavItem]="i"
    [disabled]="i !== 0 && !isValid(field.fieldGroup[i - 1])">
        <a ngbNavLink>{{tab.templateOptions.label}}</a>
        <ng-template ngbNavContent>
        <formly-field [field]="tab"></formly-field>
        </ng-template>
    </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<label class="form-label" for="currencyInput">{{to?.label}} <span *ngIf="to?.required" class="text-danger">*</span></label>
<div class="d-flex justify-content-center align-items-center">
    <div *ngIf="to?.showCurrency" class="currency-img-border px-3 d-lg-block d-none padding-cust">
        <p class="my-auto fw-bold">INR</p>
    </div>
    <div *ngIf="to?.showCurrency" class="currency-img-border px-2 d-lg-none d-block padding-cust-mobile">
        <p class="my-auto fw-bold">INR</p>
    </div>
    <input id="currencyInput" [value]="commaSeperatedValue" [placeholder]="to?.placeholder" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" (keyup)="amountEntered($event?.target?.value)" type="text" [ngClass]="to?.showCurrency ? 'show-currency': ''"
        class="form-control" [formControl]="formControl" [class.is-invalid]="showError">
</div>
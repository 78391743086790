<div class="mb-3">
    <label *ngIf="to?.label" class="form-label" for="select">{{to?.label}} 
        <span *ngIf="to?.required && !to?.hideRequiredMarker" class="text-danger">*</span>
    </label>
    <select (click)="checkOptionFromList(formControl?.value); click($event)" [formControl]="formControl" [class.is-invalid]="showError" name="select" [class.font-weight]="optionNotFromList" class="form-select" aria-label="Default select example">
        <option class="fw-light" [value]="undefined" *ngIf="to?.placeholder" selected disabled><span
                class="text-danger">{{to?.placeholder}}</span></option>
        <ng-container *ngFor="let option of to?.options">
            <ng-container *ngIf="to?.showValue; else showLabel">
                <option [value]="option?.value">{{option.value}}</option>
            </ng-container>
            <ng-template #showLabel>
                <option [value]="option?.value">{{option.label}}</option>
            </ng-template>
        </ng-container>
    </select>
</div>
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-multi-select',
  templateUrl: './formly-field-multi-select.component.html',
  styleUrls: ['./formly-field-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldMultiSelectComponent extends FieldType implements OnInit {

  constructor() { super(); }

  ngOnInit(): void {
  }

}

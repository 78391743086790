import { Component} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-link-button',
  templateUrl: './formly-field-link-button.component.html',
  styleUrls: ['./formly-field-link-button.component.scss']
})
export class FormlyFieldLinkButtonComponent extends FieldType {
  constructor(){
    super();
  }
  onClick(event: any) {
    if (this.to.onClick) {
      this.to.onClick(event);
    }
  }

}

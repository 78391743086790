<ng-container *ngIf="config">
    <div class="mb-3 bg-white rounded-1 position-absolute w-100 " [ngClass]="{zIndex: config.showMobileView}">
        <div class="d-flex justify-content-between pt-3 px-3" [ngClass]="{shadow: config.showHeaderShadow}">
            <div class="d-flex justify-content-start">
                <p *ngIf="config?.icon" class="mb-2 pb-2 fs-1"><img [src]=config?.icon class="img img-fluid" width="30" height="30" alt="icon"></p>
                <p (click)="back()" class="cursor-pointer mb-1 me-3" *ngIf="!config?.icon&& !config.showBackButton"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="fw-bold" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.56945 18.8201C9.37945 18.8201 9.18945 18.7501 9.03945 18.6001L2.96945 12.5301C2.67945 12.2401 2.67945 11.7601 2.96945 11.4701L9.03945 5.40012C9.32945 5.11012 9.80945 5.11012 10.0995 5.40012C10.3895 5.69012 10.3895 6.17012 10.0995 6.46012L4.55945 12.0001L10.0995 17.5401C10.3895 17.8301 10.3895 18.3101 10.0995 18.6001C9.95945 18.7501 9.75945 18.8201 9.56945 18.8201Z"
                            fill="#008DEA" />
                        <path
                            d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
                            fill="#008DEA" />
                    </svg>
                </p>

                <p class="fw-bold mb-2 pb-2 text-center" [ngClass]="{'ms-1 mt-2': config.icon !== undefined,'fs-3 mt-n1': !config.showMobileView,'text-primary-dark': showHeaderWeb}">{{config.title}}</p>
            </div>



            <p (click)="logout()" class="mb-2 pb-2" *ngIf="config?.logout">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.2405 22.27H15.1105C10.6705 22.27 8.5305 20.52 8.1605 16.6C8.1205 16.19 8.4205 15.82 8.8405 15.78C9.2405 15.74 9.6205 16.05 9.6605 16.46C9.9505 19.6 11.4305 20.77 15.1205 20.77H15.2505C19.3205 20.77 20.7605 19.33 20.7605 15.26V8.73998C20.7605 4.66998 19.3205 3.22998 15.2505 3.22998H15.1205C11.4105 3.22998 9.9305 4.41998 9.6605 7.61998C9.6105 8.02998 9.2605 8.33998 8.8405 8.29998C8.4205 8.26998 8.1205 7.89998 8.1505 7.48998C8.4905 3.50998 10.6405 1.72998 15.1105 1.72998H15.2405C20.1505 1.72998 22.2505 3.82998 22.2505 8.73998V15.26C22.2505 20.17 20.1505 22.27 15.2405 22.27Z"
                        fill="#292D32" />
                    <path
                        d="M14.9991 12.75H3.61914C3.20914 12.75 2.86914 12.41 2.86914 12C2.86914 11.59 3.20914 11.25 3.61914 11.25H14.9991C15.4091 11.25 15.7491 11.59 15.7491 12C15.7491 12.41 15.4091 12.75 14.9991 12.75Z"
                        fill="#292D32" />
                    <path
                        d="M5.84945 16.1001C5.65945 16.1001 5.46945 16.0301 5.31945 15.8801L1.96945 12.5301C1.67945 12.2401 1.67945 11.7601 1.96945 11.4701L5.31945 8.12009C5.60945 7.83009 6.08945 7.83009 6.37945 8.12009C6.66945 8.41009 6.66945 8.89009 6.37945 9.18009L3.55945 12.0001L6.37945 14.8201C6.66945 15.1101 6.66945 15.5901 6.37945 15.8801C6.23945 16.0301 6.03945 16.1001 5.84945 16.1001Z"
                        fill="#292D32" />
                </svg>
            </p>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="configHeader && !showHeaderWeb">
    <div class="mb-3 bg-white rounded-1">
        <div class="d-flex justify-content-between pt-3 px-3 mb-n2 pb-0">
            <div class="d-flex justify-content-start">
                <p (click)="backHeader(configHeader?.back?.link,configHeader?.back?.step)" class="mb-1 me-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="fw-bold" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.56945 18.8201C9.37945 18.8201 9.18945 18.7501 9.03945 18.6001L2.96945 12.5301C2.67945 12.2401 2.67945 11.7601 2.96945 11.4701L9.03945 5.40012C9.32945 5.11012 9.80945 5.11012 10.0995 5.40012C10.3895 5.69012 10.3895 6.17012 10.0995 6.46012L4.55945 12.0001L10.0995 17.5401C10.3895 17.8301 10.3895 18.3101 10.0995 18.6001C9.95945 18.7501 9.75945 18.8201 9.56945 18.8201Z"
                            fill="#008DEA" />
                        <path
                            d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
                            fill="#008DEA" />
                    </svg>
                </p>

                <p class="fw-bold mb-2 pb-2 text-center">{{configHeader?.title}}</p>
            </div>

            <p *ngIf="configHeader?.logout" (click)="logoutConfigHeader()" class="mb-2 pb-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.2405 22.27H15.1105C10.6705 22.27 8.5305 20.52 8.1605 16.6C8.1205 16.19 8.4205 15.82 8.8405 15.78C9.2405 15.74 9.6205 16.05 9.6605 16.46C9.9505 19.6 11.4305 20.77 15.1205 20.77H15.2505C19.3205 20.77 20.7605 19.33 20.7605 15.26V8.73998C20.7605 4.66998 19.3205 3.22998 15.2505 3.22998H15.1205C11.4105 3.22998 9.9305 4.41998 9.6605 7.61998C9.6105 8.02998 9.2605 8.33998 8.8405 8.29998C8.4205 8.26998 8.1205 7.89998 8.1505 7.48998C8.4905 3.50998 10.6405 1.72998 15.1105 1.72998H15.2405C20.1505 1.72998 22.2505 3.82998 22.2505 8.73998V15.26C22.2505 20.17 20.1505 22.27 15.2405 22.27Z"
                        fill="#292D32" />
                    <path
                        d="M14.9991 12.75H3.61914C3.20914 12.75 2.86914 12.41 2.86914 12C2.86914 11.59 3.20914 11.25 3.61914 11.25H14.9991C15.4091 11.25 15.7491 11.59 15.7491 12C15.7491 12.41 15.4091 12.75 14.9991 12.75Z"
                        fill="#292D32" />
                    <path
                        d="M5.84945 16.1001C5.65945 16.1001 5.46945 16.0301 5.31945 15.8801L1.96945 12.5301C1.67945 12.2401 1.67945 11.7601 1.96945 11.4701L5.31945 8.12009C5.60945 7.83009 6.08945 7.83009 6.37945 8.12009C6.66945 8.41009 6.66945 8.89009 6.37945 9.18009L3.55945 12.0001L6.37945 14.8201C6.66945 15.1101 6.66945 15.5901 6.37945 15.8801C6.23945 16.0301 6.03945 16.1001 5.84945 16.1001Z"
                        fill="#292D32" />
                </svg>
            </p>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="configHeader && showHeaderWeb">
    <div class="mb-3 bg-white rounded-1">
        <div class="d-flex justify-content-between align-items-center pt-3 px-3 mb-n2 pb-0">
            <div class="d-flex justify-content-start">
                <p (click)="backHeader(configHeader?.back?.link,configHeader?.back?.step)" class="mb-1 me-3 cursor-pointer"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="fw-bold" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.56945 18.8201C9.37945 18.8201 9.18945 18.7501 9.03945 18.6001L2.96945 12.5301C2.67945 12.2401 2.67945 11.7601 2.96945 11.4701L9.03945 5.40012C9.32945 5.11012 9.80945 5.11012 10.0995 5.40012C10.3895 5.69012 10.3895 6.17012 10.0995 6.46012L4.55945 12.0001L10.0995 17.5401C10.3895 17.8301 10.3895 18.3101 10.0995 18.6001C9.95945 18.7501 9.75945 18.8201 9.56945 18.8201Z"
                            fill="#008DEA" />
                        <path
                            d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
                            fill="#008DEA" />
                    </svg>
                </p>
                <p class="fw-bold mb-2 pb-2 text-primary-dark fs-5">{{configHeader?.title}}</p>

            </div>

            <div class="d-flex justify-content-end align-items-end">
                <div>
                    <img src="../../../../assets/logo/message-question.svg " alt="icon" class="img img-fluid mx-4 ">
                    <div class="text-decoration-underline text-secondary cursor-pointer" (click)="raiseARequest()">Need Help?
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #content let-modal>
    <div class="modal-body">
        <div class="px-5">
            <div class="text-center text-primary-dark mb-6 py-4">
                <h4 class="mb-2">Your request has been send successfully. Our team will get back to you in 2 3 working days
                </h4>
                <p class="text-muted">Thanks for co-operating with us </p>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-n6">
                <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1677050781144woman%20in%20online%20meeting%20with%20graphic%20tablet.png" alt="Thank you" class="img img-fluid">
            </div>
        </div>
    </div>
    <div class="modal-footer px-4">
        <a class="btn btn-primary w-100 text-light" (click)="modal.close('Close click');">Thank
            you!</a>
    </div>
</ng-template>
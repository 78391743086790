<div class="row w-100">
    <div class="col-12 col-md-6">
        <select class="form-select" [formControl]="operator" aria-label="Select type">
            <option value="">Select</option>
            <option value="gt">Greater Than</option>
            <option value="lt">Less Than </option>
            <option value="between">Between</option>
        </select>
    </div>
    <div class="col-12 col-md-6">
        <div class="row">
            <div class="col-sm-12 mb-2">
                <input
                type="date"
                [formControl]="fromNumber"
                class="form-control"
                [formlyAttributes]="field"
                [class.is-invalid]="showError"/>
            </div>
            <div class="col-sm-12">
                <input
                type="date"
                [formControl]="toNumber"
                class="form-control"
                [formlyAttributes]="field" 
                [class.is-invalid]="showError"
                *ngIf="operator.value === 'between'"/>
            </div>
        </div>
    </div>
    
    
</div>
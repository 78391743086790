import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-repeat',
  templateUrl: './formly-field-repeat.component.html',
  styleUrls: ['./formly-field-repeat.component.css']
})
export class FormlyFieldRepeatComponent extends FieldArrayType {

  constructor() { 
    super();
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-config-generator',
  templateUrl: './formly-field-config-generator.component.html',
  styleUrls: ['./formly-field-config-generator.component.scss']
})
export class FormlyFieldConfigGeneratorComponent extends FieldType implements OnInit {
  fieldModel = {};
  fieldForm = new UntypedFormGroup({})
  fields: FormlyFieldConfig[] = [
    {
      key: "fields",
      type: "repeat",
      wrappers: ["form-field"],
      templateOptions: {
        addText: 'Add new field',
        removeText: "Remove a field"
      },
      fieldArray: {
          fieldGroupClassName:"row",
          fieldGroup: [
            {
              className: "col-sm-12 col-md-6",
              key: "type",
              type: "select",
              templateOptions:{
                label: "Field Type",
                placeholder: "Field Type",
                options: [
                  {
                    label: "Text",
                    value: "input"
                  },
                  {
                    label: "Number",
                    value: "number"
                  },
                  {
                    label: "Email",
                    value: "email"
                  },
                  {
                    label: "Textarea",
                    value: "textarea"
                  }
                ]
              }
            },
            {
              className: "col-sm-12 col-md-6",
              key: "key",
              type: "input",
              templateOptions: {
                label: "Key",
                placeholder: "Key",
                pattern: /^[A-Za-z]+$/
              },
              validation:{
                messages:{
                  pattern: "Key value should only contain alphabets"
                }
              }
            },
            {
              key: "templateOptions",
              fieldGroupClassName: "row",
              fieldGroup:[
                {
                  className: "col-sm-12 col-md-6",
                  key: "label",
                  type: "input",
                  templateOptions: {
                    label: "Label",
                    placeholder: "Label"
                  }
                },
                {
                  className: "col-sm-12 col-md-6",
                  key: "placeholder",
                  type: "input",
                  templateOptions: {
                    label: "Placeholder",
                    placeholder: "Placeholder"
                  }
                },
                {
                  className: "col-sm-12 col-md-6",
                  key: "description",
                  type: "textarea",
                  templateOptions: {
                    label: "Description",
                    placeholder: "Description",
                    rows: 3
                  }
                }
              ]
            }
          ]
      }
    }
  ]; 
  constructor() {
    super();
   }

  ngOnInit(): void {
    if(this.formControl.value){
      this.fieldModel = { fields:  this.formControl.value};
    }
  }

  updateData(data:any){
    this.formControl.setValue(data.fields);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';

@Pipe({
  name: 'sentencecase',
})

export class SentenceCase implements PipeTransform {

    transform(value: string): string {
        return startCase(value);
      }
}
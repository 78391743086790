import { Injectable, EventEmitter} from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PassDataToEditLoanAppService {

  public passDataToEditLoanAppEvent = new EventEmitter();

  constructor() { }

}
import { Directive, Input, OnInit, ElementRef, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "../services/auth.service";

@Directive({
  selector: '[isUserRole]'
})
export class IsUserRoleDirective implements OnInit {
  private _role: string | string[];

  @Input() set isUserRole(role: string | string[]){
    this._role = role;
    this.checkAccess();
  }
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private auth: AuthService
    ) {
    }

  ngOnInit() {
  }
  checkAccess() {
    if(this.auth.isUserRole(this._role)){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}


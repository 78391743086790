<div class="pb-2" *ngIf="autoCompleteOptions?.multiple">
    <mat-chip-list>
        <mat-chip *ngFor="let singleItem of selectedItems" (removed)="removeItem(singleItem)">
            {{singleItem.label}}
            <button type="button" matChipRemove>
            <i class="bi bi-x"></i>
          </button>
        </mat-chip>
    </mat-chip-list>
</div>
<input type="search" [formControl]="searchControl" [matAutocomplete]="auto" [placeholder]="placeholder" class="form-control" [class.is-invalid]="control.invalid && (searchControl.touched || searchControl.dirty)" (change)="removeOption()" (search)="checkOption()"
    maxlength="80">
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="optionSelected($event)">
    <mat-option *ngFor="let item of autoCompleteData | async" [value]="item.value" ngbTooltip="{{item.label}}">
        {{ item.label }}
    </mat-option>
</mat-autocomplete>
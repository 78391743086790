import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WebConfigInterface } from '../../interfaces/common-interface';
import { Router } from '@angular/router';
import { LoanGtmService } from '../../services/loan-gtm.service';
import { ApiHelperService } from '../../services/api-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'apnst-common-web-header',
  templateUrl: './common-web-header.component.html',
  styleUrls: ['./common-web-header.component.scss']
})
export class CommonWebHeaderComponent implements OnInit {
  @Input() data: WebConfigInterface;
  @ViewChild('content') content;
  constructor(
    private route: Router,
    private loanService: LoanGtmService,
    private apiHelper: ApiHelperService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }
  logout() {
    this.route.navigate([this.data.logout])
  }

  raiseARequest() {
    this.modalService.open(this.content, { centered: true });
    let loanRequest: any = {
      id: this.loanService?.studentDetails?.id,
      name: `${this.loanService.studentDetails?.firstName + " " + this.loanService.studentDetails?.lastName}`,
      email: this.loanService.studentDetails?.email,
      mobile: this.loanService.studentDetails?.mobile,
      loanType: this.loanService.loanDetails?.loanType
    };

    if (this.loanService?.studentDetails?.parentId) {
      loanRequest.partnerName = this.loanService?.partnerDetails?.name;
      loanRequest.showAgentCode = this.loanService?.partnerDetails?.company?.showAgentCode ? true : false
    }
    this.apiHelper.postData("send-loan-application-request", loanRequest).subscribe(() => {
      this.apiHelper.openSnackBar("Sent request Successfully!");
    })
  }

  prepareViewLoanAmount(amount: string) {
    return (Number((amount))).toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      currency: 'INR'
    }).replace("$", "").split(".")[0].toString();
  }

}

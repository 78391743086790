import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formy-field-radio',
  templateUrl: './formy-field-radio.component.html',
  styleUrls: ['./formy-field-radio.component.scss'],
})
export class FormyFieldRadioComponent extends FieldType {
  defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'inline', // 'default' | 'inline'
    },
  };
}

import { Component} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-date-filter',
  templateUrl: './formly-field-date-filter.component.html',
  styleUrls: ['./formly-field-date-filter.component.css']
})
export class FormlyFieldDateFilterComponent extends FieldType {

  numberFormGroup = this.fb.group({
    operator: ["gt"],
    from: [],
    to: [null]
  })
  constructor(private fb: UntypedFormBuilder) { 
    super();
  }

  ngOnInit(): void {
    this.numberFormGroup.valueChanges.subscribe(data=>{
      this.formControl.setValue(data);
    })
  }

  get type() {
    return this.to.type || 'text';
  }

  get operator(){
    return this.numberFormGroup.get("operator");
  }
  get fromNumber(){
    return this.numberFormGroup.get("from");
  }
  get toNumber(){
    return this.numberFormGroup.get("to");
  }

}

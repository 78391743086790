<div *ngIf="!to?.isCardSelect &&!to?.isTooltip">
    <p class="mb-1 fs-5" *ngIf="to?.attributes?.filterTitle">{{to?.attributes?.filterTitle}}</p>
    <div [ngClass]="{'border border-1 border-dark py-2 px-2 mb-2 rounded-3':to?.attributes?.class==='sidebarFilter'}">
        <div *ngFor="let option of to.options | selectOptions: field | async; let i = index"
            [class]="{'border border-1 border-dark py-2 px-2 mb-2 rounded-3':to?.attributes?.class!=='sidebarFilter'}"
            [ngClass]="{
                'form-check-inline': to.formCheck === 'inline' || to.formCheck === 'inline-switch',
                'form-switch': to.formCheck === 'switch' || to.formCheck === 'inline-switch'
              }">
            <input type="checkbox" [id]="id + '_' + i" class="form-check-input mx-2 mt-2 p-2" [value]="option.value"
                [checked]="option.disabled || isChecked(option)" (change)="onChange(option.value, $any($event.target).checked)"
                [disabled]="option.disabled" />
            <label class="form-check-label me-2 mt-1 pt-1" [for]="id + '_' + i">
                {{ option.label }}
            </label>
            <img [src]="option.icon" class="img-fluid" />
            <hr *ngIf="to?.attributes?.class==='sidebarFilter'" style="margin: 10px;">
        </div>
    </div>
</div>
<!-- <div class="d-flex justify-content-between">
    <p>Choose the service modules you are looking for</p>
    <p><u>Select all</u></p>
</div> -->
<div *ngIf="to?.isCardSelect && !to?.isTooltip" class="d-flex justify-content-between text-light mb-5 pe-5 ms-n5">
    <ng-container *ngFor="let option of to.options">
        <div>
            <div class="card marketplaceCard bg-primary ms-1 me-2" *ngIf="option.value=='financialSolutions'">
                <div class="d-flex justify-content-around pt-3">
                    <input type="checkbox" [id]="id + '_' + i" class="form-check-input border border-light"
                        [value]="option.value" [checked]="isChecked(option)"
                        (change)="onCardChange(option.value, $any($event.target).checked)" [disabled]="option.disabled"
                        [checked]="option.disabled" />
                    <label class="form-check-label" [for]="id + '_' + i">
                        <p class="fs-5 fw-bold">Finance Solutions</p>
                    </label>
                </div>
                <div class="card-body">
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>Education Loans</p>
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>Student Housing</p>
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>GIC</p>
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>Blocked Account</p>
                    <p class="my-0 mb-4"><i class="bi bi-check-lg me-2"></i>Forex Transfers</p>
                    <div class="text-center">
                        <input type="checkbox" [id]="id + '_' + i" class="form-check-input d-none"
                            [value]="option.value" [checked]="isChecked(option)"
                            (change)="onCardChange(option.value, $any($event.target).checked)"
                            [disabled]="option.disabled" [checked]="option.disabled" />
                        <label class="form-check-label" [for]="id + '_' + i">
                            <h5 class="btn btn-light text-primary">Select</h5>
                        </label>
                    </div>
                </div>
            </div>

            <div class="card visaCard bg-dark" *ngIf="option.value=='travelSolutions'">
                <div class="d-flex justify-content-evenly pt-3">
                    <input type="checkbox" [id]="id + '_' + i+1" class="form-check-input border border-light"
                        [value]="option.value" [checked]="isChecked(option)"
                        (change)="onCardChange(option.value, $any($event.target).checked)" [disabled]="option.disabled"
                        [checked]="option.disabled" />
                    <label class="form-check-label" [for]="id + '_' + i+1">
                        <p class="fs-5 fw-bold">Travel Solutions</p>
                    </label>
                </div>
                <div class="card-body">
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>Bank Account</p>
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>Flight Tickets</p>
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>Forex Cards</p>
                    <p class="my-0"><i class="bi bi-check-lg me-2"></i>Sim Cards</p>
                    <p class="my-0 mb-4"><i class="bi bi-check-lg me-2"></i>Insurance</p>
                    <div class="text-center">
                        <input type="checkbox" [id]="id + '_' + i+1" class="form-check-input d-none"
                            [value]="option.value" [checked]="isChecked(option)"
                            (change)="onCardChange(option.value, $any($event.target).checked)"
                            [disabled]="option.disabled" [checked]="option.disabled" />
                        <label class="form-check-label" [for]="id + '_' + i+1">
                            <h5 class="btn btn-light text-primary">Select</h5>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="to?.isTooltip &&!to?.isCardSelect" class="d-flex justify-content-center  align-items-center">
    <p class="mb-1 fs-5" *ngIf="to?.attributes?.filterTitle">{{to?.attributes?.filterTitle}}</p>
    <div [ngClass]="{'border border-1 border-dark py-2 px-2 mb-2 rounded-3':to?.attributes?.class==='sidebarFilter'}">
        <div *ngFor="let option of to.options | selectOptions: field | async; let i = index" class="ms-2 align-items-center">
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    <input type="checkbox" [id]="id + '_' + i" class="form-check-input mx-2 mt-2 p-2"
                        [value]="option.value" [checked]="isChecked(option)"
                        (change)="onChange(option.value, $any($event.target).checked)" [disabled]="option.disabled"
                        [checked]="option.disabled" />
                    <label class="form-check-label me-2 mt-1 pt-1" [for]="id + '_' + i">
                        {{ option.label }}
                    </label>

                    <img [src]="option.icon" class="img-fluid" />
                    <hr *ngIf="to?.attributes?.class==='sidebarFilter'" style="margin: 10px;">
                </li>
            </ul>

        </div>
    </div>
</div>
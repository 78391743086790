import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [
    CommonModule,
    MatDialogModule,
  ],
 exports: [TooltipComponent, TooltipDirective]
})
export class TooltipModule { }

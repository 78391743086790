<ng-container *ngIf="cardConfig?.showcarousel" class="mb-4">
    <div id="carouselExample" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let single of cardConfig?.cardData; index as i" [ngClass]="{ 'active': i === 0 }">
                <div class="card border-0 position-relative me-3">
                    <div class="card-body d-flex justify-content-between {{single?.background}} rounded-2 border-0">
                        <div class="row h-100">
                            <div class="col-9">
                                <div class="mb-2">
                                    <h5 class="card-text mb-4 {{single?.textClass}}">{{single?.heading}}</h5>
                                    <a [href]=single?.href target="_blank" class="btn {{single?.btnClass}} rounded-1" *ngIf="single?.cta && !single?.mailto">{{single?.cta}}</a>
                                    <a class="{{single?.mailtoClass}} rounded-1 mb-1" *ngIf="single?.mailto" href="mailto:{{single?.mailto}}">{{single?.cta}}</a>
                                </div>
                            </div>
                            <div class="col-4 position-absolute bottom-0 end-0">
                                <img [src]="single?.icon" class="img ms-n2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ol class="carousel-indicators rounded-bottom">
            <li data-bs-target="#carouselExample" *ngFor="let single of cardConfig?.cardData; index as i" [attr.data-bs-slide-to]="i" [ngClass]="{ 'active': i === 0 }"></li>
        </ol>
    </div>
</ng-container>

<ng-container *ngIf="!cardConfig?.showcarousel && !webConfig">
    <div class="card border-0 position-relative me-2">
        <div class="card-body d-flex justify-content-between {{cardConfig?.singleCardData.background}} rounded-2 border-0">
            <div class="row">
                <div class="col-9">
                    <div class="mb-2">
                        <h5 class="{{cardConfig?.singleCardData.textClass}}">{{cardConfig?.singleCardData?.heading}}
                        </h5>
                        <p class="{{cardConfig?.singleCardData.textClass}}" *ngIf="cardConfig?.singleCardData?.subheading">{{cardConfig?.singleCardData?.subheading}}
                        </p>
                        <button class="{{cardConfig?.singleCardData?.btnClass}} rounded-custom mb-n2" (click)="route()">{{cardConfig?.singleCardData?.cta}}</button>
                    </div>
                </div>
                <div class="col-4 position-absolute bottom-0 end-0">
                    <img [src]="cardConfig?.singleCardData?.icon" class="img ms-custom">
                </div>
            </div>
        </div>
    </div>


</ng-container>

<ng-container *ngIf="!cardConfig?.showcarousel && webConfig">
    <div class="card border-0 position-relative me-2 h-66">
        <div class="card-body d-flex justify-content-between {{cardConfig?.singleCardData.background}} rounded-2 border-0 position-relative">
            <div class="align-items-center mx-auto">
                <div class="text-center">
                    <h4 class="{{cardConfig?.singleCardData.textClass}}">{{cardConfig?.singleCardData?.heading}}</h4>
                    <p class="{{cardConfig?.singleCardData.textClass}}" *ngIf="cardConfig?.singleCardData?.subheading">{{cardConfig?.singleCardData?.subheading}}</p>
                    <button class="{{cardConfig?.singleCardData?.btnClass}} rounded-custom mb-n2" (click)="route()">{{cardConfig?.singleCardData?.cta}}</button>
                </div>
                <div class="image-container">
                    <img [src]="cardConfig?.singleCardData?.icon" class="img img-fluid" alt="icon">
                    <!-- <img [src]="cardConfig?.singleCardData?.icon"> -->
                </div>
            </div>
        </div>
    </div>

</ng-container>
<cdk-accordion-item #accordionItem="cdkAccordionItem" [expanded]="to.expanded">
    <div class="accordion-item h-2">
        <h2 class="accordion-header" id="headingOne" (click)="accordionItem.toggle()">
            <button class="accordion-button" [ngClass]="{'collapsed' : !accordionItem.expanded}" type="button">
                <div class="row">
                    <div class="col-12">
                        {{to.label}}
                    </div>
                    <div class="col-12 text-muted">
                        {{to.subLabel}}
                    </div>
                </div>
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
            [ngClass]="{'show' : accordionItem.expanded}" [attr.id]="'accordion-body'"
            [attr.aria-labelledby]="'accordion-header'">
            <div class="accordion-body">
                <ng-container #fieldComponent></ng-container>
            </div>
        </div>
    </div>
</cdk-accordion-item>
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgxOtpInputConfig } from 'ngx-otp-input';

@Component({
  selector: 'apnst-formly-field-otp',
  templateUrl: './formly-field-otp.component.html',
  styleUrls: ['./formly-field-otp.component.scss']
})
export class FormlyFieldOtpComponent extends FieldType {
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: "flex-fill ",
      input: "w-100 input-height mb-2",
      container: "d-flex"
    }
  };
  
  feedFormControl(event:any){
    this.formControl.setValue(event);
  }
}

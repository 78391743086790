<!-- <p>formly-field-inline-repeat works!</p>-->
<div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
    <div class="col-9">
    <formly-field [field]="field"></formly-field>
    </div>
    <div class="col-3 mt-4 pt-1" *ngIf="!to.hideRemoveButton && i>=1">
      <button class="btn text-danger" type="button" (click)="remove(i)">{{to.removeBtnText}} <i-feather name="trash"></i-feather></button>
    </div>
    <div *ngIf="!to.hideAddButton && i===0" class="col-2 mt-4 pt-1" >
      <button class="btn" type="button" (click)="add()">{{ to.addText }} <i-feather name="plus"></i-feather></button>
    </div>
  </div>

import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyConfig } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-custom-input',
  templateUrl: './formly-field-custom-input.component.html',
  styleUrls: ['./formly-field-custom-input.component.scss']
})
export class FormlyFieldCustomInputComponent extends FieldType implements OnInit {
  customField;

  types: string[];

  constructor(private formlyConfig: FormlyConfig){
    super();
    this.types = Object.keys(this.formlyConfig.types).filter(x => x != 'custom-input');
  }

  ngOnInit() {
    this.field.wrappers = [];
  }
}

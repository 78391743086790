import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TwoWayBindService {
  updateData: any;
  constructor() { }

  updateSendData(updatedData) {
    this.updateData = { ...updatedData };
    return this.updateData;
  }
}

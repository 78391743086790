import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Required } from '../../decorators/decorator';
import { ApiHelperService } from '../../services/api-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'aerp-base-schema-form',
  templateUrl: './base-schema-form.component.html',
  styleUrls: ['./base-schema-form.component.scss']
})
export class BaseSchemaFormComponent implements OnInit {


  loading = false;
  @Input() form: any;
  @Input() isModel: any = false;
  @Input() schema: any;
  @Input() modalTitle: any;
  @Input() @Required layout: any[];
  @Input() dataModel: any;
  @Input() @Required model: any;
  @Input() dataProcessor: (data: any, schema: any, layout: any[]) => object;
  // tslint:disable-next-line
  @Output('onSubmitResponse') SubmitResponse: EventEmitter<any> = new EventEmitter<any>();
  // tslint:disable-next-line
  @Output('onSubmit') Submit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("formRef", { static: false }) formRef: ElementRef<HTMLFormElement>

  @ViewChild("openErrorPopup") openErrorPopup;
  public formSchema: any = [];
  public formLayout: any = [];
  private isFormValid = false;

  constructor(
    public service: ApiHelperService,
    public ngbModal: NgbModal
  ) {
  }

  ngOnInit() {
    this.modalTitle = this.modalTitle || (this.model.id ? 'EDIT DETAILS' : 'ADD DETAILS');

    if (this.isModel) {
      this.layout.push({
        type: 'button',
        className: "d-none",
        templateOptions: {
          btnType: "primary d-none",
          type: "submit",
          text: 'Save'
        }
      })
    }

    this.setSchemaDef = this.schema;

    this.setLayout = this.layout;

    this.formSchema = this.getSchema;

    this.formLayout = this.getLayout;

    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }
  }

  ngAfterViewInit() {
    if (this.isModel) {
      document.querySelector('form apnst-formly-field-button')?.setAttribute('hidden', 'true');
    }
  }



  submitForm(event) {
    let submitBtn: any = document.querySelector('form apnst-formly-field-button').querySelector('button[type=submit]');

    submitBtn.click();
  }

  // This function will handle Json Schema Form OnSubmit event
  onFormSubmit(data) {
    this.form.value.mobile=data?.mobile
    this.isFormValid = this.form.valid;
    // Show message if form is not valid
    if (!this.form.valid || this.form.invalid) {
      if (data?.error) {
       return this.ngbModal.open(this.openErrorPopup,{
          centered: true,
          size: 'l'
        })
      }
      return this.service.openSnackBar('Please fill out all the required fields or check errors in form.', { duration: 5000, });
    }

    let newData = data;

    // Process form data ie. add or remove properties
    if (this.dataProcessor && typeof this.dataProcessor === 'function') {
      newData = this.dataProcessor(data, this.schema, this.layout);
      if (!newData) { throw new Error('dataProcessor() has not returned proper json object.'); }
    }
    this.model = newData;

    // Component will handle form submit
    if (this.Submit.observers.length) {
      return this.Submit.emit({ data: data, isValid: this.form.valid });
    }

    // NOTE: Global form submit action
    if (this.model?.id) {
      this.loading = true;
      this.service.updateData(this.dataModel, this.model?.id, this.model).subscribe(
        (response) => {
          this.loading = false;
          this.SubmitResponse.emit(response); 
          // this.service.openSnackBar('Successfully Updated!');
          this.resetForm();
        }, (err) => {
          this.loading = false;
          this.SubmitResponse.emit(err); this.service.openSnackBar(this.processMessage(err), { duration: 6000 });
        }
      );
    } else {
      this.loading = true;
      this.service.postData(this.dataModel, this.model).subscribe(
        (res) => {
          this.loading = false;
          this.SubmitResponse.emit(res); 
          // this.service.openSnackBar('Successfully Submitted!');
          this.resetForm();
        },
        (err) => {
          this.loading = false;
          this.SubmitResponse.emit(err); this.service.openSnackBar(this.processMessage(err), { duration: 6000 });
        }
      );
    }
  }

  goBack(){
    this.ngbModal.dismissAll();
  }
  // TODO: onchange in form just emit data to particular event handler
  onChanges(data) {
  }

  processMessage(err: any) {
    let msg: string = err.error?.error?.message || err.statusText;
    let startIndex = msg.indexOf('Details') > -1 ? msg.indexOf('Details') : 0;
    return msg.substr(startIndex).replace('Details: ', '');
  }

  resetForm() {
    if (this.formRef) {
      this.formRef.nativeElement.reset();
    }
  }



  set setSchemaDef(schema: any) {
    this.formSchema = schema;
  }
  set setLayout(layout: any) {
    this.formLayout = layout;
  }
  get getSchema() {
    return this.formSchema;
  }
  get getLayout() {
    return this.formLayout;
  }
}


import { Component} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-multicheckbox',
  templateUrl: './formly-field-multicheckbox.component.html',
  styleUrls: ['./formly-field-multicheckbox.component.scss']
})
export class FormlyFieldMulticheckboxComponent extends FieldType {
  defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'inline', // 'default' | 'inline' | 'switch' | 'inline-switch'
    },
  };

  onChange(value: any, checked: boolean) {

    if (this.to.type === 'array') {
      this.formControl.patchValue(
        checked
          ? [...(this.formControl.value || []), value]
          : [...(this.formControl.value || [])].filter((o) => o !== value),
      );
    } else {
      this.formControl.patchValue({ ...this.formControl.value, [value]: checked });
    }
    this.formControl.markAsTouched();
  }

  onCardChange(value: any, checked: boolean) {

    if (this.to.type === 'array') {
      this.formControl.patchValue(
        checked
          ? [...(this.formControl.value || []), value]
          : [...(this.formControl.value || [])].filter((o) => o !== value),
      );
    } else {
      this.formControl.patchValue({ ...this.formControl.value, [value]: checked });
    }
    this.formControl.markAsTouched();
  }
  isChecked(option: any) {
    const value = this.formControl?.value;
    return value && (this.to.type === 'array' ? value.indexOf(option.value) !== -1 : value[option.value]);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { CardInterface } from '../../interfaces/common-interface';
import { Router } from '@angular/router';

@Component({
  selector: 'apnst-common-card-wrapper',
  templateUrl: './common-card-wrapper.component.html',
  styleUrls: ['./common-card-wrapper.component.scss']
})
export class CommonCardWrapperComponent implements OnInit {
  @Input() cardConfig: CardInterface
  @Input() webConfig: boolean
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  route() {
    this.router.navigate([this.cardConfig.singleCardData?.href])
  }

}

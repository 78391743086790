import { Component, Input, OnInit, TemplateRef } from '@angular/core';
export interface AvatarConfig {
  avatar: boolean
}
@Component({
  selector: 'apnst-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})

export class TooltipComponent implements OnInit {
  @Input() text: string;
  @Input() content: TemplateRef<any>;
  @Input() contents: TemplateRef<any>;
  @Input() config: AvatarConfig = {
    avatar: false
  };
  constructor() { }

  ngOnInit(): void {
  }

}

 <!--Generic-->
 <ng-container *ngFor="let field of field.fieldGroup">
 <ng-container [ngSwitch]="field.type">  
    <ng-container *ngFor="let type of types">  
    <formly-field *ngSwitchCase="type"
      [model]="field.model"
      [form]="form"
      [field]="field"
      [options]="options">
    </formly-field>   
    </ng-container>  
  </ng-container>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-select-new',
  templateUrl: './formly-select-new.component.html',
  styleUrls: ['./formly-select-new.component.scss']
})
export class FormlySelectNewComponent extends FieldType implements OnInit {
  optionNotFromList:boolean = true;

  ngOnInit(): void {
    this.checkOptionFromList(this.formControl.value);
  }

  click(event: any) {
    if (this.to?.click) {
      this.to?.click(event);
    }
  }

  checkOptionFromList(value){
    if(value){
      this.to?.options?.forEach((single:any)=>{
        if(single?.value === this.formControl.value) {
          this.optionNotFromList = false;
        };
      })
    }
  }
}
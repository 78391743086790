import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-tiny-mce',
  templateUrl: './formly-field-tiny-mce.component.html',
  styleUrls: ['./formly-field-tiny-mce.component.scss']
})
export class FormlyFieldTinyMceComponent extends FieldType implements OnInit {
  public config = {
    plugins: 'link paste lists fullscreen image link charmap hr anchor toc wordcount help emoticons',
    toolbar: 'undo redo | bold italic | formatselect | outdent indent | numlist bullist checklist | casechange permanentpen removeformat | emoticons | fullscreen |  image link anchor codesample | a11ycheck ltr rtl',
    paste_as_text: true,
    height: '300px'
  }

  ngOnInit(): void {
    if (this.to.tinyMceOptions) {
      this.config = Object.assign(this.config, this.to.tinyMceOptions);
    }
  }


}

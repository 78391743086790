<div class="row">
    <div class="col-12">
        <p class="mb-0 text-center">
            {{formControl.value | json}}
        </p>
        <div class="d-flex">
            <p class="text-muted mb-0 text-end">{{this.to?.min??0}}</p>
            <input type="range" [formControl]="formControl" class="form-range px-2" [class.is-invalid]="showError"
                [min]="this.to.min" [max]="this.to.max">
            <p class="text-muted mb-0">{{this.to?.max??100}}</p>
        </div>

        <!-- <input type="range" [formControl]="formControl" class="form-range" [formlyAttributes]="field"
            [class.is-invalid]="showError" /> -->
    </div>

</div>
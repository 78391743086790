import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'projects/foreign-admits/src/environments/environment';

export interface SeoObj {
  title: string,
  metaKey: string | string[],
  metaDesc: string,
  metaImage?: string,
  pageUrl?: string
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  public title: string;
  private metaImageUrl: string;

  constructor(
    private meta: Meta,
    private titleService: Title,
  ) {
   
    this.metaImageUrl = `${environment.path}assets/logo/foreignadmits/foreignadmits-dark.png`;
  }

  addSeo(title: string, metaKeywords: string | string[], metaDesc: string, metaImage?: string, pageUrl?: string) {
    let metaKey: any = metaKeywords;
    let metaKeyStr: string = metaKey?.join ? metaKey.join(',') : String(metaKey);
    this.titleService.setTitle(title);
    this.meta.addTags([
      { name: 'keywords', content: metaKeyStr },
      { name: 'description', content: metaDesc },
      { name: 'og:title', content: title },
      { name: 'og:description', content: metaDesc },
      { name: 'og:url', content: environment.path + pageUrl },
      { name: 'og:image', content: metaImage || this.metaImageUrl },
    ]);
  }

  addSeoObj(seo: SeoObj) {
    if (seo && seo.title) {
      this.addSeo(seo.title, seo.metaKey, seo.metaDesc, seo['metaImg'] || seo.metaImage, seo.pageUrl);
    }
  }

}

import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { CountryISO, PhoneNumberFormat, SearchCountryField,  } from 'ngx-intl-tel-input';
import  { PhoneNumberUtil } from "google-libphonenumber";
import { distinct } from "rxjs/operators";
import { OnlyCountryISO } from './countryiso.enum';
@Component({
  selector: 'apnst-formly-field-mobile-number',
  templateUrl: './formly-field-mobile-number.component.html',
  styleUrls: ['./formly-field-mobile-number.component.scss']
})
export class FormlyFieldMobileNumberComponent extends FieldType implements OnInit, AfterViewInit {
  separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  selectedCountry = CountryISO.India;
  allCountries = Object.values(OnlyCountryISO);
  onlyCountries: OnlyCountryISO[] = this.allCountries;
  phoneControl = new UntypedFormControl(null);
  numberFormat = PhoneNumberFormat.International;
  phoneUtil = PhoneNumberUtil.getInstance();
  constructor(private cd: ChangeDetectorRef){
    super();
  }
  onClick(event: any) {
    if (this.to.onClick) {
      this.to.onClick(event);
    }
  }
  onPhoneChange(ev:any){
    if(this.phoneControl.valid){
      if(this.checkValidIndianMobileNumber(ev)){
        if(this.phoneControl.value && this.phoneControl.value.e164Number){
          this.formControl.setValue(this.phoneControl.value.e164Number);
        }
      }
    } else {
      if(ev?.dialCode === "+91"){
        if(this.phoneControl.value.e164Number.replace("+91", "").length>10 || this.phoneControl.value.e164Number.replace("+91", "").length<10){
          this.formControl.setValue("")
        }
      }
      else{
        this.formControl.setValue("")
      }
    }
  }

  checkValidIndianMobileNumber(event:any){
    if(event?.dialCode === "+91"){
      if(this.phoneControl.value.hasOwnProperty("e164Number")){
        return this.phoneControl.value.e164Number.replace("+91", "").length === 10
      }
    }
    return true;
  }

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(distinct()).subscribe(data=>{
      if(data) {
        this.setPhoneControl(data);
      }
    })
  }

  ngAfterViewInit(): void {
    if(this.formControl.value){
      const rawNumber = this.formControl.value;
      this.setPhoneControl(rawNumber);
    }
    if(this.formControl.disabled){
      this.phoneControl.disable();
    } else {
      this.phoneControl.enable();
    }
    this.formControl.statusChanges.subscribe(data=>{
      if(!this.formControl.disabled){
        this.phoneControl.enable();
      } else {
        this.phoneControl.disable();
      }
    })
  }
  setPhoneControl(rawNumber: string){
    let dialCodeNumber = this.phoneUtil.parse(rawNumber).getCountryCode();
    let dialCode = "+" + dialCodeNumber;
    let countryCode = this.phoneUtil.getRegionCodeForCountryCode(dialCodeNumber);
    this.phoneControl.setValue( { number: rawNumber.replace(dialCode, ""), dialCode :dialCode, countryCode });
    // this.formControl.markAsTouched();
    this.cd.detectChanges();
  }
}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-code-editor',
  templateUrl: './formly-field-code-editor.component.html',
  styleUrls: ['./formly-field-code-editor.component.scss']
})
export class FormlyFieldCodeEditorComponent extends FieldType implements OnInit, AfterViewInit {
  constructor() {
    super();
   }

  ngOnInit(): void {
  }
  ngAfterViewInit() {}

}

import { Component } from '@angular/core';
import { FieldWrapper, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-wrapper-nav',
  templateUrl: './formly-wrapper-nav.component.html',
  styleUrls: ['./formly-wrapper-nav.component.scss']
})
export class FormlyWrapperNavComponent extends FieldWrapper {
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every((f) => this.isValid(f))
      : true;
  }
}

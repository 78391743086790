import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-inline-repeat',
  templateUrl: './formly-field-inline-repeat.component.html',
  styleUrls: ['./formly-field-inline-repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldInlineRepeatComponent extends FieldArrayType {

  

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigInterface, HeaderInterface } from '../../interfaces/common-interface';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiHelperService } from '../../services/api-helper.service';
import { LoanGtmService } from '../../services/loan-gtm.service';
@Component({
  selector: 'apnst-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})

export class CommonHeaderComponent implements OnInit {
  @Input() config: ConfigInterface;
  @Input() configHeader: HeaderInterface;
  @Input() showHeaderWeb: boolean;
  @Output() stepEmitted = new EventEmitter<string>();
  @Output() headerStepEmitted = new EventEmitter<any>();
  @ViewChild('content') content;
  logoutIcon: any;
  constructor(
    private route: Router,
    private loanService: LoanGtmService,
    private apiHelper: ApiHelperService,
    private modalService: NgbModal
  ) {

  }

  ngOnInit(): void {

  }

  logout() {
    this.route.navigate([this.config.logout])
  }

  logoutConfigHeader() {
    this.route.navigate([this.configHeader?.logout])
  }

  backHeader(link: string, step: string) {
    let obj = {
      link: link,
      step: step
    }
    this.headerStepEmitted.emit(obj);
  }


  back() {
    if (!this.config.stepEmitter) {
      this.route.navigate([this.config.backLink])
    }
    else {
      this.stepEmitted.emit(this.config.stepEmitter)
    }
  }

  raiseARequest() {
    this.modalService.open(this.content, { centered: true });
    let loanRequest: any = {
      id: this.loanService?.studentDetails?.id,
      name: `${this.loanService.studentDetails?.firstName + " " + this.loanService.studentDetails?.lastName}`,
      email: this.loanService.studentDetails?.email,
      mobile: this.loanService.studentDetails?.mobile,
      loanType: this.loanService.loanDetails?.loanType
    };

    if (this.loanService?.studentDetails?.parentId) {
      loanRequest.partnerName = this.loanService?.partnerDetails?.name;
      loanRequest.showAgentCode = this.loanService?.partnerDetails?.company?.showAgentCode ? true : false
    }
    this.apiHelper.postData("send-loan-application-request", loanRequest).subscribe(() => {
      this.apiHelper.openSnackBar("Sent request Successfully!");
    })
  }

  prepareViewLoanAmount(amount: string) {
    return (Number((amount))).toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      currency: 'INR'
    }).replace("$", "").split(".")[0].toString();
  }

}

import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-wrapper-accordian-item',
  templateUrl: './formly-wrapper-accordian-item.component.html',
  styleUrls: ['./formly-wrapper-accordian-item.component.scss']
})
export class FormlyWrapperAccordianItemComponent extends FieldWrapper {

}

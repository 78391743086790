<div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
    <formly-field [field]="field"></formly-field>
    <div class="col-lg-12 d-flex align-items-center justify-content-end" *ngIf="!to.hideRemoveButton">
      <ng-container *ngIf="!to.hideRemoveButton">
        <button class="btn btn-link text-danger" type="button" (click)="remove(i)">Remove {{to.removeBtnText}}</button>
      </ng-container>
      
    </div>
  </div>
  <div *ngIf="!to.hideAddButton">
    <button class="btn btn-outline-primary ms-3" type="button" (click)="add()">{{ to.addText }}</button>
  </div>

/* export function Required(target: object, propertyKey: string) {
  Object.defineProperty(target, propertyKey, {
    get() {
      throw new Error(`Attribute/Input Property ${propertyKey} is required`);
    },
    set(value) {
      Object.defineProperty(target, propertyKey, { value, writable: true, configurable: true });
    },
  });
} */

const noop = () => {};

export function Required(target: any, prop: string) {

  const NG_ON_ONIT_NAME = 'ngOnInit';

  /** ngOnInit might not be implemented by this component */
  const ngOnInitClone: Function|null = target[NG_ON_ONIT_NAME];

  Object.defineProperty(target, NG_ON_ONIT_NAME, {
    value: function() {
      
      if ( this[prop] == null ) {
        let err = target.constructor.name + `: ${prop} is required, but was not provided`;
        throw new Error(err);
      }
      (ngOnInitClone || noop).call(this);
    }
  });
}

import { Component} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-range-slider',
  templateUrl: './formly-field-range-slider.component.html',
  styleUrls: ['./formly-field-range-slider.component.scss']
})
export class FormlyFieldRangeSliderComponent extends FieldType {

  // get type() {
  //   return this.to.type || 'text';
  // }

}

import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHelperService } from 'projects/apna-lib/src/lib/services/api-helper.service';
import { ConfigService } from 'projects/apna-lib/src/lib/services/config.service';
import { UserIdentityService } from 'projects/apna-lib/src/lib/services/user-identity.service';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'fa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ForeignAdmits';
  constructor(
    private config: ConfigService
  ) {
    // this.config.getAllConfig()
  }
}

<div [class.modal-content]="isModel">
    <div class="preloader-container" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="isModel" class="modal-header">
        <h3 class="modal-title">{{ modalTitle }}</h3>
        <button class="btn-close" matDialogClose></button>
    </div>
    <div *ngIf="!loading && form" [class.modal-body]="isModel">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)" (valueChanges)="onChanges($event)" autocomplete="off" #formRef>
            <formly-form [form]="form" [fields]="formLayout" [model]="model"></formly-form>
        </form>
    </div>
    <div *ngIf="isModel" class="modal-footer">
        <button type="submit" class="ms-auto btn btn-primary" (click)="submitForm($event)">Save</button>
        <button type="submit" class="btn btn-outline-danger" matDialogClose> Cancel </button>
    </div>
</div>

<ng-template #openErrorPopup let-modal>
    <div class="modal-body">
        <div class="d-flex justify-content-center align-items-center flex-column">
            <img class="mb-4"
                src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1699001972962Frame%201000001985.png"
                alt="errorTriangle">
            <h3 class="text-center mb-4">Please fill out all the required fields or check errors in form</h3>
            <button (click)="goBack()" class="btn btn-primary-goback text-light px-6 rounded-1 fw-bold">Go Back</button>
        </div>
    </div>
</ng-template>
import { Component, OnInit} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-radio-v2',
  templateUrl: './formly-field-radio-v2.component.html',
  styleUrls: ['./formly-field-radio-v2.component.scss']
})
export class FormlyFieldRadioV2Component extends FieldType implements OnInit {

  defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'default', // 'default' | 'inline'
    },
  };

  checkedValues:string[];

  ngOnInit(): void {
    if(this.to?.attributes && this.to?.attributes?.checkedValues && typeof this.to?.attributes?.checkedValues=='string'){
      this.checkedValues = JSON.parse(this.to?.attributes?.checkedValues);
    }
  }

}

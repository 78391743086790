<ng-container *ngIf="!data?.tableData || data?.tableData?.filePath?.length == 0">
    <h5 class='text-muted'>Example of Document</h5>
    <p class='text-muted'>Your document must look like dummy document</p>
    <div class='d-flex justify-content-center align-items-center'>
        <img class='img img-fluid' width='130' [src]='this.data?.sampleImageUrl' />
    </div>
</ng-container>
<ng-container *ngIf="data?.tableData?.filePath?.length > 0">
    <div class="table-responsive">
        <table class="table table-hover table-striped align-middle text-center">
            <thead>
              <tr class="text-muted align-middle">
                <th>Sr.No.</th>
                <th *ngFor="let col of data?.columns" scope="col">{{col}}</th>
                <th>Download</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let element of data?.tableData?.filePath; index as i">
                <th>{{i+1}}</th>
                <td><p class="wrap mb-0">{{getDocumentName(element)}}</p></td>
                <td><button (click)="downloadAction(element, $event)" class="btn bg-transparent text-primary"><i class="bi bi-download"></i></button></td>
                <td><button (click)="deleteAction(element, $event)" class="btn bg-transparent text-danger"><i class="bi bi-trash3"></i></button></td>
              </tr>
            </tbody>
          </table>
    </div>
    
</ng-container>
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';

import { FreshToken } from '../interfaces/user-details';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { EnvironmentVars } from '../interfaces/environment-vars';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

export interface HttpHeaderOptions {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    @Inject('environment') private environment: EnvironmentVars
  ) { }

  private serverPath: string = this.environment.pathapi;
  private pageItems = 100;
  private skipItems = 0;
  private currentPage = 0;

  resetLimits() {
    this.pageItems = 10;
    this.skipItems = 0;
  }

  paginator(page) {
    this.pageItems = page.pageSize;
    this.skipItems = page.pageIndex * page.pageSize;
    this.currentPage = page.pageIndex + 1;
  }

  public setServerPath(path) {
    this.serverPath = path;
  }

  public getServerPath() {
    return this.serverPath;
  }

  public getDataById(model, modelId) {
    return this.http.get(`${this.serverPath}/${model}/${modelId}`);
  }

  public getDataByIdAndFilter(model, modelId, filterObj: any) {
    return this.http.get(`${this.serverPath}/${model}/${modelId}`, {
      params: { filter: JSON.stringify(filterObj) }
    });
  }

  public postData(model, data) {
    return this.http.post(`${this.serverPath}/${model.replace("/search", "")}`, data);
  }

  public deleteData(model, modelId) {
    return this.http.delete(`${this.serverPath}/${model.replace("/search", "")}/${modelId}`);
  }
  public deleteMultipleData(model, options: { body?: any }) {
    return this.http.delete(`${this.serverPath}/${model.replace("/search", "")}`, options);
  }
  public searchData(model, searchTerm, filter: any = null) {
    if (searchTerm?.includes("/")) {
      searchTerm = searchTerm.replace("/", " ");
    }
    return new Promise((resolve, reject) => {
      let params = filter ? { filter } : null;
      if (!params) {
        return this.http.get(`${this.serverPath}/${model.replace("/search", "")}/aggregate/${searchTerm}`)
          .subscribe(data => {
            resolve(data);
          }, err => {
            reject(err);
          });
      }
      if (params !== null) {
        params.filter = JSON.stringify(params.filter);
      }

      this.http.get(`${this.serverPath}/${model.replace("/search", "")}/aggregate/${searchTerm}`, { params })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  getUserDetails() {
    const userId = localStorage.getItem('userId');
    return this.http.get(this.serverPath + '/users/' + userId);
  }

  getCount(model, filter) {
    const params = filter.where ? { params: { where: JSON.stringify(filter.where) } } : { params: {} };
    return this.http.get(`${this.serverPath}/${model.replace("/search", "")}`, params);
  }

  public updateData(model, modelId, data) {
    return this.http.patch(`${this.serverPath}/${model.replace("/search", "")}/${modelId}`, data);
  }
  // public searchData(model,)  

  public prepareFilter(fields, searchValue, filterObj: any = {}) {
    // const filterObj: any = {};
    const filterValue = searchValue.trim();

    if (!filterObj.where) {
      filterObj.where = { or: [] };
    } else {
      filterObj.where = {
        and: [filterObj.where, { or: [] }]
      }
    }
    for (const ele of fields) {
      let orFilter = {};
      orFilter[ele] = { like: filterValue, options: 'i' };
      if (filterObj.where && filterObj.where.and) {
        filterObj.where.and[1].or.push(orFilter);
      } else {
        filterObj.where.or.push(orFilter);
      }
    }
    return filterObj;
  }

  public getData(api: string, filter: any = null, isPagination: boolean = false) {
    return new Promise((resolve, reject) => {
      let params = filter ? { filter } : null;
      if (isPagination) {
        if (this.currentPage) {
          if (!params) { params = { filter: {} }; }
          params.filter.limit = this.pageItems;
          params.filter.skip = this.skipItems;
        }
      }
      if (params !== null) {
        params.filter = JSON.stringify(params.filter);
      }

      this.http.get(`${this.serverPath}/${api}`, { params })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }
  openSnackBar(message: string, options?: MatSnackBarConfig, action?: string) {
    const defaultOptions: MatSnackBarConfig = {
      duration: 2000,
    };

    if (options) {
      Object.assign(defaultOptions, options);
    }

    if (!action) { action = ''; }

    this.snackBar.open(message, action, defaultOptions);
  }

  public flatten(obj) {
    const root = {};
    this.tree(root, obj, '');
    return root;
  }
  private tree(root, obj, index) {
    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) { continue; }
      root[index + key] = obj[key];
      if (toString.call(obj[key]) === '[object Object]') {
        this.tree(root, obj[key], index + key + '.');
      }
      if (toString.call(obj[key]) === '[object Array]') {
        root[index + key + '.length'] = obj[key].length;
        this.tree(root, obj[key], index + key + '.');

      }
    }
  }

  downloadCsvFromRows(filename: string, rows: any[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.downloadCsv(csvContent, filename);
  }

  public downloadCsv(csvContent: string, filename: string = 'data.csv') {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator['msSaveBlob']) { // IE 10+
      navigator['msSaveBlob'](blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  public updateMultiple(model, modelIds: string[], data) {
    const arrayID = modelIds.map(single => {
      return { id: single }
    })
    return this.http.patch(`${this.serverPath}/${model.replace("/search", "")}`, data, {
      params: {
        where: JSON.stringify({ or: arrayID })

      }
    });
  }

  public updateMultipleRecord(model, where: any, data) {
    return this.http.patch(`${this.serverPath}/${model.replace("/search", "")}`, data, {
      params: {
        where: JSON.stringify(where)

      }
    });
  }

  downloadCsvFromApi(api: string, fields: any, filter: any) {
    this.spinner.show();
    this.http.get(this.serverPath + '/exports/' + api, {
      headers: {
        "Accept": "text/csv"
      },
      params: {
        fieldObj: JSON.stringify({ fields: JSON.stringify(fields) }),
        filter: JSON.stringify(filter)
      },
      responseType: "text"
    }).subscribe((csv: any) => {
      var exportedFilenmae = api + '.csv' || 'export.csv';

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.spinner.hide();
      }
    })
  }


  public downloadZip(model, modelId, category?: string) {
    if (category) {
      return this.http.get(`${this.serverPath}/${model}/${modelId}/${category}`, {
        responseType: "text"
      });
    }
    return this.http.get(`${this.serverPath}/${model}/${modelId}`, {
      responseType: "text"
    });
  }


  public watchEventSource(model: string) {
    let url = this.serverPath + "/" + model;
    if (localStorage.getItem('token')) {
      url += `?authorization=Bearer ${localStorage.getItem('token')}`;
    }
    return new EventSource(url);
  }

  public generateSignedUrl(folderName: string, fileName: string): Observable<{ signedUrl: string }> {
    return this.http.get(`${this.serverPath}${'/generate-signed-url/'}/${folderName}/${fileName}`) as Observable<{ signedUrl: string }> ;
  }

  downloadDocumentByS3Uri(url: string) {
    if (url && url.indexOf("com/") > 0) {
      const [, pathVariable] = url.split("com/");
      const [folderName, fileName] = pathVariable.split("/");
      this.spinner.show();
      this.generateSignedUrl(folderName, fileName)
        .subscribe(({ signedUrl }: { signedUrl: string }) => {
          const a: any = document.createElement('a');
          a.style = 'display: none';
          document.body.appendChild(a);
          a.href = signedUrl;
          a.download = fileName;
          a.click();
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
          });
    }
  }

  getCountryFilteredServices(services: any, targetCountry?: string) {
    if (targetCountry) {
      if (targetCountry == "US") {
        services = services.filter((service: any) => {
          if (service?.value != "Gic" && service?.value != "BlockAccount") {
            return service;
          }
        })
      }
      else if (targetCountry == "Germany") {
        services = services.filter((service: any) => {
          if (service?.value != "InterviewPreparation" && service?.value != "Gic") {
            return service;
          }
        })
      }
      else if (targetCountry == "Canada") {
        services = services.filter((service: any) => {
          if (service?.value != "InterviewPreparation" && service?.value != "BlockAccount") {
            return service;
          }
        })
      }
      else {
        services = services.filter((service: any) => {
          if (service?.value != "InterviewPreparation" && service?.value != "BlockAccount" && service?.value != "Gic") {
            return service;
          }
        })
      }
    }
    services = services.filter((service: any) => {
      if (service?.value != "General" && service?.value != "Visa" && service?.value != "FlightTicket") return service;
    })

    return services;
  }

}

import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'apnst-formly-field-autocomplete',
  templateUrl: './formly-field-autocomplete.component.html',
  styleUrls: ['./formly-field-autocomplete.component.scss']
})
export class FormlyFieldAutocompleteComponent extends FieldType {

  constructor() {
    super();
  }
}

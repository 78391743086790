import { Component, Input, OnInit } from '@angular/core';
import { StepperInterface } from '../../interfaces/common-interface';

@Component({
  selector: 'apnst-material-stepper',
  templateUrl: './material-stepper.component.html',
  styleUrls: ['./material-stepper.component.scss']
})
export class MaterialStepperComponent implements OnInit {
  @Input() steps: StepperInterface;
  constructor() { }

  ngOnInit(): void {
  }
  calculateProgressWidth(): string {
    if (this.steps.totalSteps === 3) {
      if (window?.innerWidth < 374) {
        return '95';

      }
      if (window?.innerWidth >= 375 &&window?.innerWidth < 1199) {
        return '130';

      }
      else {
        return '280';
      }
    }
    else {
      if (window?.innerWidth < 374) {
        return '53';

      }
      if (window?.innerWidth >= 375 && window?.innerWidth < 1199) {
        return '70';
      }
      if (window?.innerWidth >= 1200 && window?.innerWidth < 1500) {
        return '110';
      }
      else {
        return '140';
      }

    }
  }

  getProgressBarWidth(): string {
    const stepCount = this.steps.steps?.length;
    return (100 / (stepCount - 1) * 1) + '%';
  }

}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiHelperService } from '../../services/api-helper.service';
import { MediaService } from '../../services/media.service';

@Component({
  selector: 'apnst-formly-field-file',
  templateUrl: './formly-field-file.component.html',
  styleUrls: ['./formly-field-file.component.scss'],
})
export class FormlyFieldFileComponent extends FieldType implements OnInit, AfterViewInit {
  imgLink:any;

  mimeTypes = {
    "file" : {
      type: [".jpg", ".jpeg", ".png", ".gif", ".pdf", ".mp4"],
      mime: ["image/jpg","image/jpeg", "image/png", "image/gif", "application/pdf", "video/mp4"],
    },
    "document":{
      types: [".pdf"],
      mime: ["application/pdf"]
    },
    "image": {
      types: [".jpg",".png",".gif"],
      mime: ["image/jpg","image/jpeg","image/png", "image/gif"]
    },
    "video":{
      type: [".mp4"],
      mime: ["video/mp4"]
    }
  }
  progress:number = 0;
  @ViewChild("fileInput") fileInputRef: ElementRef<HTMLInputElement>
  constructor(
    private spinner: NgxSpinnerService,
    private mediaService: MediaService,
    private cd: ChangeDetectorRef,
    private apiHelperService: ApiHelperService){
    super();
  }
  get type() {
    
    return this.field?.type??".pdf";
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    
    setTimeout(()=>{
      this.generateImageUrl(this.field.formControl.value);
    }, 200)
    
  }

  generateImageUrl(url: string){
    this.imgLink = null;
    if (url && url.indexOf("com/") > 0) {
      const [, pathVariable] = url.split("com/");
      const [folderName, fileName] = pathVariable.split("/");
      this.spinner.show();
      this.apiHelperService.generateSignedUrl(folderName, fileName)
        .subscribe(({ signedUrl }: { signedUrl: string }) => {
          this.imgLink = signedUrl;
          this.cd.detectChanges();
          
          this.spinner.hide();
          return true
          // this.cd.detach();
          
        },
          error => {
            this.spinner.hide();
          });
    }
    return true;
  }

  uploadMedia(fileList:FileList){
    const file = fileList.item(0);
    if(!this.mimeTypes["file"]['mime'].includes(file.type)){
      this.fileInputRef.nativeElement.value = "";
      this.apiHelperService.openSnackBar("Please upload valid file");
      return;
    }
    if( file.size > 5*1024*1024){
      this.apiHelperService.openSnackBar("Please upload file of size less than 5mb");
      this.fileInputRef.nativeElement.value = "";
      return;
    }
    this.mediaService.uploadMedia(file).subscribe((data: any)=>{
      if(data && data.progress){
        this.progress = data.progress;
      }
      if(data && data.body){
        this.formControl.setValue(data.body[0]["Location"]);
        this.fileInputRef.nativeElement.value = "";
        this.progress = 0;
      }
      this.imgLink = null;
      this.generateImageUrl(this.formControl.value);
      this.formControl.markAsTouched();
      // this.cd.detectChanges();
    })
  }
  removeImage(){
    this.formControl.setValue(null);
  }

  isImage(url: string){
      var arr = [ "jpeg", "jpg", "gif", "png"];
      var ext = url.substring(url.lastIndexOf(".")+1);
      if(arr.includes(ext)){
        return true;
      } else {
        return false;
      }
  }

  downloadDocumentByS3Uri(url: string) {
    if (url && url.indexOf("com/") > 0) {
      const [, pathVariable] = url.split("com/");
      const [folderName, fileName] = pathVariable.split("/");
      this.spinner.show();
      this.apiHelperService.generateSignedUrl(folderName, fileName)
        .subscribe(({ signedUrl }: { signedUrl: string }) => {
          const a: any = document.createElement('a');
          a.style = 'display: none';
          document.body.appendChild(a);
          a.href = signedUrl;
          a.download = fileName;
          a.click();
          this.spinner.hide();
        },
          error => {
            this.spinner.hide();
          });
    }
  }
}

<div class="container">
    <ng-container *ngFor="let item of steps.steps; index as i">
        <div [ngClass]="{complete: item.status === 'completed',active: item.status === 'in progress','check-box':steps.totalSteps===5,'check-box-small':steps.totalSteps===3}">
            <!-- start -->
            <div [ngClass]="{complete: item.status === 'completed',active: item.status === 'in progress'}" class="progress-line-start"  *ngIf="i === 0 && !item.icon ">
                <div class="progress-percent"></div>
            </div>
            <div class="checkboxs" [ngClass]="{complete: item.status === 'completed',active: item.status === 'in progress'}" *ngIf="!item.icon" >
                <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1715330773012ticked.png" *ngIf="item.status === 'completed'" class="d-flex justify-content-center img img-fluid  mx-auto mt-2-custom">
            </div>
            <div class="checkIcon" [ngClass]="{complete: item.status === 'completed',active: item.status === 'in progress'}" *ngIf="item.icon">
                <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1715330773012ticked.png" *ngIf="item.status === 'completed'" alt="icon" class="d-flex justify-content-center img img-fluid  mx-auto mt-2-custom">
                <img [src]="item.icon" *ngIf="item.status !== 'completed'" alt="icon" class="d-flex justify-content-center img img-fluid  mx-auto" />
            </div>
            <!-- actual line of progress -->
            <div [ngClass]="{ complete: item.status === 'completed',active: item.status === 'in progress', step:steps.totalSteps!==3}" class="progress-line"  [ngStyle]="{ 'width.px': calculateProgressWidth() }" *ngIf="i !== steps.totalSteps - 1">
                <div class="progress-percent"></div>
            </div>
            <!-- end -->
            <div [ngClass]="{complete: item.status === 'completed',active: item.status === 'in progress'}" class="progress-line-end" *ngIf="i===steps.totalSteps-1 && !item.icon">
                <div class="progress-percent"></div>

            </div>
            <div class="d-flex justify-content-center align-items-center text-center position-absolute" [ngClass]="{info:steps.totalSteps!==3,info1:steps.totalSteps==3,'ms-n5':i===0&&steps.totalSteps!==3}">
                {{ item.title }}
            </div>            
        </div>

    </ng-container>
</div>
<div class="py-4 mb-2">
    <ng-content select="app-progress-step"></ng-content>
</div>
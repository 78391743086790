<p class="mb-1 fs-5" *ngIf="to?.attributes?.filterTitle">{{to?.attributes?.filterTitle}}</p>
<div *ngIf="!to?.showDocLayout" [ngClass]="{'border border-1 border-dark py-2 px-2 mb-2 rounded-3 ':to?.attributes?.sidebarclass==='sidebarRadioFilter'}" class="style-left">
    <div *ngFor="let option of to.options | selectOptions: field | async; let i = index" class="form-check" [class.form-check-inline]="to.formCheck === 'inline'">
        <input type="radio" [id]="id + '_' + i" class="form-check-input" [ngClass]="to?.attributes?.inputClassName" [name]="field.name || id" [class.is-invalid]="showError" [attr.value]="option.value" [value]="option.value" [formControl]="formControl" [formlyAttributes]="field"
            [attr.disabled]="option.disabled || formControl.disabled ? true : null" />
        <label class="form-check-label" [ngClass]="to?.attributes?.labelClassName" [class.text-success]="checkedValues && checkedValues?.includes(option?.value)" [for]="id + '_' + i">
            <div *ngIf="to?.startIcon&& to?.endIcon;else default" class="d-flex align-items-center">
                <img [src]="checkedValues?.includes(option?.value)? to?.checkedIcon: to?.startIcon" alt="icon"
                    class="me-3">
                <span [ngClass]="to?.attributes?.labelClassName"
                    [class.text-success]="checkedValues && checkedValues?.includes(option?.value)">
                    {{ option.label }}
                </span>
                <img class="ms-auto" alt="icon"
                    [src]="checkedValues?.includes(option?.value)?to.editIcon:to?.endIcon" />
            </div>
            <ng-template #default>
                {{ option.label }}
                <ng-container *ngIf="checkedValues && checkedValues?.includes(option?.value)">
                    <i class="bi bi-check-circle-fill"></i>
                </ng-container>
            </ng-template>

        </label>
    </div>
    <!--  -->
</div>

<div class="row row-cols-2 mb-n3 me-n5" *ngIf="to?.showDocLayout">
    <div *ngFor="let option of to.options | selectOptions: field | async; let i = index" class="form-check col-4 mb-0 style-left" [class.form-check-inline]="to.formCheck === 'inline'">
        <input type="radio" [id]="id + '_' + i" class="form-check-input" [ngClass]="to?.attributes?.inputClassName" [name]="field.name || id" [class.is-invalid]="showError" [attr.value]="option.value" [value]="option.value" [formControl]="formControl" [formlyAttributes]="field"
            [attr.disabled]="option.disabled || formControl.disabled ? true : null" />
        <label class="form-check-label" [ngClass]="to?.attributes?.labelClassName" [class.text-success]="checkedValues && checkedValues?.includes(option?.value)" [for]="id + '_' + i">
            <div *ngIf="to?.startIcon&& to?.endIcon;else default" class="d-flex align-items-center">
                <img [src]="checkedValues?.includes(option?.value)? to?.checkedIcon: to?.startIcon" height="25" width="25"
                    class="img img-fluid" alt="icon" class="me-3">
                <span [ngClass]="to?.attributes?.labelClassName" 
                    [class.text-success]="checkedValues && checkedValues?.includes(option?.value)">
                    {{ option.label }}
                </span>
                <img class="ms-auto img img-fluid mb-0" alt="icon" height="25" width="25"
                    [src]="checkedValues?.includes(option?.value)?to.editIcon:to?.endIcon" />
            </div>
            <ng-template #default>
                {{ option.label }}
                <ng-container *ngIf="checkedValues && checkedValues?.includes(option?.value)">
                    <i class="bi bi-check-circle-fill"></i>
                </ng-container>
            </ng-template>

        </label>
    </div>
</div>
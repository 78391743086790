<div *ngIf="formControl.value && !to?.isMobileConfig" class="card rounded-2 mb-4 bg-transparent border border-0" title="Hold Ctrl(PC) or Command(Mac) key to select multiple files">
    <div class="img-wrapper d-flex justify-content-start flex-wrap">
        <ng-container *ngIf="type === 'files'">
            <ng-container *ngFor="let value of formControl.value">
                <ng-container *ngIf="isImage(value); else fileTemplate">
                    <ng-container *ngIf="value?.includes('public'); else privateImage">
                        <div class="d-flex flex-column justify-content-start align-items-start m-2">
                            <img *ngIf="value" [src]="value" class="uploaded-image uniform-size" width="80" height="80" />
                            <span class="text-dark fs-6 fw-bold mt-0">{{ getFileNameFromS3Path(value) }}</span>
                        </div>
                    </ng-container>
                    <ng-template #privateImage>
                        <div class="d-flex flex-column justify-content-start align-items-start m-2">
                            <fa-async-image [src]="value" [width]="'80'" [height]="'80'" [styleClass]="'img img-fluid uniform-size m-2'"></fa-async-image>
                            <span class="text-dark fs-6 fw-bold mt-0">{{ getFileNameFromS3Path(value) }}</span>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-template #fileTemplate>
                    <div class="d-flex flex-column justify-content-start align-items-start m-2">
                        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1718089645286pdf.png" class="uploaded-image uniform-size" />
                        <span class="text-dark fs-6 fw-bold mt-0">{{ getFileNameFromS3Path(value) }}</span>
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1718089645286pdf.png" *ngIf="type === 'document'" class="uploaded-image m-2 uniform-size" />
        <img [src]="formControl.value" *ngIf="type === 'image'" class="uploaded-image m-2 uniform-size" />
    </div>
    <ng-container *ngIf="formControl.value && !to?.hideDeleteDownloadButton">
        <button type="button" class="btn btn-outline-danger  custom-button d-flex text-center justify-content-center w-25 mx-auto" (click)="removeImage()">Delete
        </button>
    </ng-container>
</div>
<div *ngIf="formControl.value && to?.isMobileConfig">
    <div *ngIf="files" class="bg-primary-light py-2 mx-1 d-flex justify-content-center rounded-custom text-indigo fw-bold">
        <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1715666714105document-text-indigo.png" alt="icon" class="img img-fluid me-3">
        <span class="text-indigo fw-bold fs-5 mt-0">{{getFileNameFromS3Path(files.name)}}</span>
    </div>
</div>
<!-- NEW CARD IF DATA NOT PRESENT -->

<p class="card-text text-start mb-2" *ngIf="!formControl.value && to?.hideLabel!==true">{{ to.label }} <span *ngIf="to.required && to.hideRequiredMarker !== true " class="text-danger" aria-hidden="true">*</span></p>
<div class="card position-relative rounded-2 mx-1" [ngClass]="to?.fileWrapperClass" [class.border-danger]="showError" style="border: 0 !important;" apnstDragAndDrop (fileDropped)="uploadMedia($event)" (click)="fileInput.click()" *ngIf="!formControl.value && !to?.bulk">
    <div class="card-body  py-2 d-flex justify-content-center align-items-center" [ngClass]="to?.mobileConfigClass">
        <p *ngIf="to?.placeholder" class="mb-0 me-3">{{to?.placeholder}}</p><i class="bi bi-upload fs-5" *ngIf="to?.showPlaceHolderIcon!==false"></i>
    </div>
</div>
<div class="opacity-1 align-items-center text-center justify-content-center" [ngClass]="to?.fileWrapperClass" apnstDragAndDrop (fileDropped)="uploadMedia($event)" (click)="fileInput.click()" *ngIf="!formControl.value && to?.bulk">
    <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1718085832865uploader.png" alt="uploader">
    <p *ngIf="to?.placeholder" class="mb-0 me-3 fs-4 fw-bold">{{to?.placeholder}}</p>
    <p *ngIf="to?.desc" class="mb-0 me-3">{{to?.desc}}</p>
</div>
<input type="file" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError" #fileInput (change)="uploadMedia(fileInput.files)" [hidden]="true" />
<div class="progress" *ngIf="progress && !to?.isMobileConfig">
    <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="{width: progress ? progress + '%': '0%'}" [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100"></div>
</div>

<ng-template #openErrorPopup let-modal>
    <div class="modal-body">
        <div class="d-flex justify-content-center align-items-center flex-column">
            <img class="mb-4" src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1699001972962Frame%201000001985.png" alt="errorTriangle">
            <h3 class="text-center mb-4">Please fill out all the required fields or check errors in form</h3>
            <button (click)="goBack()" class="btn btn-primary-goback text-light px-6 rounded-1 fw-bold">Go Back</button>
        </div>
    </div>
</ng-template>